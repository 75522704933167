<template>
	<b-dropdown :size="showLabel ? '' : 'sm'" :variant="showLabel ? 'outline-primary' : 'link'"
		:toggle-class="showLabel ? '' : 'text-decoration-none'" :no-caret="true" v-if="route">
		<template #button-content>
			<span v-if="showLabel">Options</span>
			<i class="fe fe-more-vertical"></i>
		</template>
		<b-dropdown-item :to="{ name: 'EditRoute', params: { routeId: route.id } }">Edit</b-dropdown-item>
		<b-dropdown-item href="#" @click.prevent="suspendRoute(route.id)">{{ this.isRouteActive ? 'Suspend' : 'Unsuspend' }}
		</b-dropdown-item>
		<b-dropdown-item href="#" @click.prevent="deleteRoute(route.id)" link-class="text-danger">Delete</b-dropdown-item>
	</b-dropdown>
</template>

<script>
export default {
  props: {
    tripId: {
      required: false,
      type: String
    }
  }
}
</script>

<style lang="scss" scoped>
.route-list-item::before {
  display: block;
  height: 30px;
  width: 3px;
  background: linear-gradient(#003d36, #20e682);
  content: '';
  float: left;
  margin-right: 10px;
  margin-top: 8px;
  margin-bottom: auto;
}

.route-pickup-title::before {
  content: '';
  display: block;
  float: left;
  width: 10px;
  height: 10px;
  background: #003d36;
  z-index: 1;
  border-radius: 10px;
  position: absolute;
  top: 10px;
  margin-left: -16px;
}

.route-destination-title::before {
  content: '';
  display: block;
  float: left;
  width: 10px;
  height: 10px;
  background: #20e682;
  z-index: 1;
  border-radius: 10px;
  position: absolute;
  top: 40px;
  margin-left: -16px;
}
</style>
